import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DistanceLearningComponent } from './components/pages/distance-learning/distance-learning.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { DdeComponent } from './components/pages/dde/dde.component';
import { UniversityComponent } from './components/pages/university/university.component';
import { DirectorMessegeComponent } from './components/pages/director-messege/director-messege.component';
import { WorldOfSubhartiComponent } from './components/pages/world-of-subharti/world-of-subharti.component';
import { AdmissionProcedureComponent } from './components/pages/admission-procedure/admission-procedure.component';
import { CreditTransferPolicyComponent } from './components/pages/credit-transfer-policy/credit-transfer-policy.component';
import { LatestEntryPolicyComponent } from './components/pages/latest-entry-policy/latest-entry-policy.component';
import { NewFeeStructureComponent } from './components/pages/new-fee-structure/new-fee-structure.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { SemesterSyllabusComponent } from './components/pages/semester-syllabus/semester-syllabus.component';
import { DetailedSyllabusComponent } from './components/pages/detailed-syllabus/detailed-syllabus.component';
import { AssignmentsComponent } from './components/pages/assignments/assignments.component';
import { DiplomaComponent } from './components/pages/diploma/diploma.component';
import { AdvancedDiplomaComponent } from './components/pages/advanced-diploma/advanced-diploma.component';
import { DegreeCoursesComponent } from './components/pages/degree-courses/degree-courses.component';
import { PgDiplomaCoursesComponent } from './components/pages/pg-diploma-courses/pg-diploma-courses.component';
import { LatestNewsComponent } from './components/pages/latest-news/latest-news.component';
import { ResutionForDdeComponent } from './components/pages/resution-for-dde/resution-for-dde.component';
import { DebComplianceComponent } from './components/pages/deb-compliance/deb-compliance.component';
import { VisionMissionComponent } from './components/pages/vision-mission/vision-mission.component';
import { FacultyDetailsComponent } from './components/pages/faculty-details/faculty-details.component';
import { DownloadComponent } from './components/pages/download/download.component';
import { EnquiryComponent } from './components/pages/enquiry/enquiry.component';
import { CourseCoOrdinatorsComponent } from './components/pages/course-co-ordinators/course-co-ordinators.component';
import { GuidelinesComponent } from './components/pages/guidelines/guidelines.component';
import { HowToGetAdmissionOpenComponent } from './components/pages/how-to-get-admission-open/how-to-get-admission-open.component';
import { WhyChooseSubhartiComponent } from './components/pages/why-choose-subharti/why-choose-subharti.component';
import { IsSubhartiGoodComponent } from './components/pages/is-subharti-good/is-subharti-good.component';
import { ExaminationScheduleComponent } from './components/pages/examination-schedule/examination-schedule.component';
import { EvalutionProcedureComponent } from './components/pages/evalution-procedure/evalution-procedure.component';
import { AllCoursesComponent } from './components/pages/all-courses/all-courses.component';
import { NewCoursesComponent } from './components/pages/new-courses/new-courses.component';
import { CourseSlmComponent } from './components/pages/course-slm/course-slm.component';
import { AdmissionDetailsComponent } from './components/pages/admission-details/admission-details.component';
import { AcademicCalenderComponent } from './components/pages/academic-calender/academic-calender.component';
import { SessionTwoComponent } from './components/pages/session-two/session-two.component';
import { SessionOneComponent } from './components/pages/session-one/session-one.component';
import { FacultyComponent } from './components/pages/faculty/faculty.component';
import { IsSubhartiUniversityDegreeValidForGovernmentJobComponent } from './components/pages/is-subharti-university-degree-valid-for-government-job/is-subharti-university-degree-valid-for-government-job.component';
import { GrievanceRedressalSystemComponent } from './components/pages/grievance-redressal-system/grievance-redressal-system.component';
import { FeedbackComponent } from './components/pages/feedback/feedback.component';
import { RecognitionsComponent } from './components/pages/recognitions/recognitions.component';
import { Assignment1Component } from './components/pages/assignment1/assignment1.component';
import { Assignment2Component } from './components/pages/assignment2/assignment2.component';
import { Assignment3Component } from './components/pages/assignment3/assignment3.component';
import { Assignment4Component } from './components/pages/assignment4/assignment4.component';
import { Assignment5Component } from './components/pages/assignment5/assignment5.component';
import { Assignment6Component } from './components/pages/assignment6/assignment6.component';
import { Assignment7Component } from './components/pages/assignment7/assignment7.component';
import { Assignment8Component } from './components/pages/assignment8/assignment8.component';
import { Assignment9Component } from './components/pages/assignment9/assignment9.component';
import { Assignment10Component } from './components/pages/assignment10/assignment10.component';
import { Assignment12Component } from './components/pages/assignment12/assignment12.component';
import { Assignment11Component } from './components/pages/assignment11/assignment11.component';
import { BachelorOfArtsComponent } from './components/pages/programme/bachelor-of-arts/bachelor-of-arts.component';
import { BachelorOfLibraryAndInformationSciencesComponent } from './components/pages/programme/bachelor-of-library-and-information-sciences/bachelor-of-library-and-information-sciences.component';
import { BachelorOfBusinessAdministrationComponent } from './components/pages/programme/bachelor-of-business-administration/bachelor-of-business-administration.component';
import { BachelorOfCommerceComponent } from './components/pages/programme/bachelor-of-commerce/bachelor-of-commerce.component';
import { BachelorOfCommerceHonorsComponent } from './components/pages/programme/bachelor-of-commerce-honors/bachelor-of-commerce-honors.component';
import { BachelorOfArtsJamcComponent } from './components/pages/programme/bachelor-of-arts-jamc/bachelor-of-arts-jamc.component';
import { MaComponent } from './components/pages/ma/ma.component';
import { MaUgComponent } from './components/pages/programme/ma-ug/ma-ug.component';
import { MaPgComponent } from './components/pages/programme/ma-pg/ma-pg.component';
import { MasterOfLibraryAndInformationSciencesComponent } from './components/pages/programme/master-of-library-and-information-sciences/master-of-library-and-information-sciences.component';
import { MasterOfBusinessAdministrationComponent } from './components/pages/programme/master-of-business-administration/master-of-business-administration.component';
import { MasterOfCommeceComponent } from './components/pages/programme/master-of-commece/master-of-commece.component';
import { MaHomeScienceComponent } from './components/pages/programme/ma-home-science/ma-home-science.component';
import { MaJournalismAndMassCommunicationComponent } from './components/pages/programme/ma-journalism-and-mass-communication/ma-journalism-and-mass-communication.component';
import { MaPublicAdministrationComponent } from './components/pages/programme/ma-public-administration/ma-public-administration.component';
import { MaBuddhistStudiesComponent } from './components/pages/programme/ma-buddhist-studies/ma-buddhist-studies.component';
import { MaEducationComponent } from './components/pages/programme/ma-education/ma-education.component';
import { AssignmentFm1st23Component } from './components/pages/assignment-fm1st23/assignment-fm1st23.component';
import { AssignmentFm2nd23Component } from './components/pages/assignment-fm2nd23/assignment-fm2nd23.component';
import { AssignmentFm3rd23Component } from './components/pages/assignment-fm3rd23/assignment-fm3rd23.component';
import { AssignmentFm4th23Component } from './components/pages/assignment-fm4th23/assignment-fm4th23.component';
import { AssignmentFm5th23Component } from './components/pages/assignment-fm5th23/assignment-fm5th23.component';
import { AssignmentFm6th23Component } from './components/pages/assignment-fm6th23/assignment-fm6th23.component';
import { HttpsGuard } from './https-guard'; // Import the HttpsGuard
import { AssignmentsAS1st23Component } from './components/pages/assignments-a-s1st23/assignments-a-s1st23.component';
import { AssignmentsAS2nd23Component } from './components/pages/assignments-a-s2nd23/assignments-a-s2nd23.component';
import { AssignmentsAS3rd23Component } from './components/pages/assignments-a-s3rd23/assignments-a-s3rd23.component';
import { AssignmentsAS4th23Component } from './components/pages/assignments-a-s4th23/assignments-a-s4th23.component';
import { AssignmentsAS5th23Component } from './components/pages/assignments-a-s5th23/assignments-a-s5th23.component';
import { AssignmentsAS6th23Component } from './components/pages/assignments-a-s6th23/assignments-a-s6th23.component';
import { ForSessionC23Component } from './components/pages/for-session-c23/for-session-c23.component';
import { StudentLoginComponent } from './components/common/student-login/student-login.component';

const routes: Routes = [
    { path: '', component: DistanceLearningComponent },
    { path: 'distance-learning', component: DistanceLearningComponent },
    { path: 'contact', component: ContactUsComponent },
    { path: 'dde', component: DdeComponent },
    { path: 'university', component: UniversityComponent },
    { path: 'director-messege', component: DirectorMessegeComponent },
    { path: 'world-of-subharti', component: WorldOfSubhartiComponent },
    { path: 'admission-procedure', component: AdmissionProcedureComponent },
    {
        path: 'credit-transfer-policy',
        component: CreditTransferPolicyComponent,
    },
    { path: 'latest-entry-policy', component: LatestEntryPolicyComponent },
    { path: 'new-fee-structure', component: NewFeeStructureComponent },
    { path: 'refund-policy', component: RefundPolicyComponent },
    { path: 'semester-syllabus', component: SemesterSyllabusComponent },
    { path: 'detailed-syllabus', component: DetailedSyllabusComponent },
    { path: 'assignments', component: AssignmentsComponent },
    {
        path: 'assignments-1st-semester-august-22',
        component: Assignment1Component,
    },
    {
        path: 'assignments-2nd-semester-august-22',
        component: Assignment2Component,
    },
    {
        path: 'assignments-3rd-semester-august-22',
        component: Assignment3Component,
    },
    {
        path: 'assignments-4th-semester-august-22',
        component: Assignment4Component,
    },
    {
        path: 'assignments-5th-semester-august-22',
        component: Assignment5Component,
    },
    {
        path: 'assignments-1st-semester-august-22',
        component: Assignment6Component,
    },
    {
        path: 'assignments-2st-semester-august-22',
        component: Assignment7Component,
    },
    {
        path: 'assignments-3st-semester-august-22',
        component: Assignment8Component,
    },
    {
        path: 'assignments-4st-semester-august-22',
        component: Assignment9Component,
    },
    {
        path: 'assignments-1st-semester-Feb-Mar-23',
        component: AssignmentFm1st23Component,
    },
    {
        path: 'assignments-2nd-semester-Feb-Mar-23',
        component: AssignmentFm2nd23Component,
    },
    {
        path: 'assignments-3rd-semester-Feb-Mar-23',
        component: AssignmentFm3rd23Component,
    },
    {
        path: 'assignments-4th-semester-Feb-Mar-23',
        component: AssignmentFm4th23Component,
    },
    {
        path: 'assignments-5th-semester-Feb-Mar-23',
        component: AssignmentFm5th23Component,
    },
    {
        path: 'assignments-6th-semester-Feb-Mar-23',
        component: AssignmentFm6th23Component,
    },
    { path: 'assignment6', component: Assignment6Component },
    { path: 'assignment7', component: Assignment7Component },
    { path: 'assignment8', component: Assignment8Component },
    { path: 'assignment9', component: Assignment9Component },
    { path: 'assignment10', component: Assignment10Component },
    { path: 'assignment12', component: Assignment12Component },
    { path: 'assignment11', component: Assignment11Component },
    { path: 'diploma', component: DiplomaComponent },
    { path: 'advanced-diploma', component: AdvancedDiplomaComponent },
    { path: 'degree-courses', component: DegreeCoursesComponent },
    { path: 'pg-diploma-courses', component: PgDiplomaCoursesComponent },
    { path: 'new-courses', component: NewCoursesComponent },
    { path: 'latest-news', component: LatestNewsComponent },
    { path: 'resolution-for-dde', component: ResutionForDdeComponent },
    { path: 'compliance', component: DebComplianceComponent },
    { path: 'vision-mission', component: VisionMissionComponent },
    { path: 'faculty-details', component: FacultyDetailsComponent },
    { path: 'downloads', component: DownloadComponent },
    { path: 'course-slm', component: CourseSlmComponent },
    { path: 'admission-details', component: AdmissionDetailsComponent },
    { path: 'for-session-2022-23', component: SessionTwoComponent },
    { path: 'faculty', component: FacultyComponent },
    {
        path: 'blog/is-subharti-university-degree-valid-for-government-job',
        component: IsSubhartiUniversityDegreeValidForGovernmentJobComponent,
    },
    { path: 'for-session-2021-22', component: SessionOneComponent },
    { path: 'recognition', component: RecognitionsComponent },
    { path: 'grievance', component: GrievanceRedressalSystemComponent },
    { path: 'feedback', component: FeedbackComponent },
    { path: 'academic-calender', component: AcademicCalenderComponent },
    { path: 'enquiry', component: EnquiryComponent },
    { path: 'course-co-ordinators', component: CourseCoOrdinatorsComponent },
    { path: 'semester-syllabus', component: SemesterSyllabusComponent },
    { path: 'guidelines', component: GuidelinesComponent },
    {
        path: 'blog/how-to-get-admission-open',
        component: HowToGetAdmissionOpenComponent,
    },
    {
        path: 'blog/why-choose-subharti-university-for-distance-learning',
        component: WhyChooseSubhartiComponent,
    },
    {
        path: 'blog/is-subharti-university-good-for-distance-mba',
        component: IsSubhartiGoodComponent,
    },
    { path: 'examination-schedule', component: ExaminationScheduleComponent },
    { path: 'evalution-procedure', component: EvalutionProcedureComponent },
    { path: 'all-courses', component: AllCoursesComponent },
    { path: 'programme/bachelor-of-arts', component: BachelorOfArtsComponent },
    {
        path: 'programme/bachelor-of-library-and-information-sciences',
        component: BachelorOfLibraryAndInformationSciencesComponent,
    },
    {
        path: 'programme/bechelor-of-business-administration',
        component: BachelorOfBusinessAdministrationComponent,
    },
    {
        path: 'programme/bachelor-of-commerce',
        component: BachelorOfCommerceComponent,
    },
    {
        path: 'programme/bachelor-of-commerce-honors',
        component: BachelorOfCommerceHonorsComponent,
    },
    {
        path: 'programme/bachelor-of-arts-jmc',
        component: BachelorOfArtsJamcComponent,
    },
    { path: 'programme/ma', component: MaPgComponent },
    { path: 'programme/ma-ug', component: MaUgComponent },
    {
        path: 'programme/master-of-library-and-information-sciences',
        component: MasterOfLibraryAndInformationSciencesComponent,
    },
    {
        path: 'programme/master-of-business-administration',
        component: MasterOfBusinessAdministrationComponent,
    },
    {
        path: 'programme/master-of-commece',
        component: MasterOfCommeceComponent,
    },
    { path: 'programme/ma-home-science', component: MaHomeScienceComponent },
    {
        path: 'programme/ma-journalism-and-mass-communication',
        component: MaJournalismAndMassCommunicationComponent,
    },
    {
        path: 'programme/ma-public-administration',
        component: MaPublicAdministrationComponent,
    },
    {
        path: 'programme/ma-buddhist-studies',
        component: MaBuddhistStudiesComponent,
    },
    { path: 'programme/ma-education', component: MaEducationComponent },
    {
        path: 'https://subhartidde.com/MSVerify.aspx?EN=MNCh%2BH0x96h058spgBC20GhJfF8MRMaG8eNQL4ZUgl0%3D&Y=1&E=R',
        component: MaEducationComponent,
        canActivate: [HttpsGuard], // Apply the HttpsGuard to this route
    },
    {
        path: 'assignments-1st-semester-January2025',
        component: AssignmentsAS1st23Component,
    },
    {
        path: 'assignments-2nd-semester-January2025',
        component: AssignmentsAS2nd23Component,
    },
    {
        path: 'assignments-3rd-semester-January2025',
        component: AssignmentsAS3rd23Component,
    },
    {
        path: 'assignments-4th-semester-January2025',
        component: AssignmentsAS4th23Component,
    },
    {
        path: 'assignments-5th-semester-January2025',
        component: AssignmentsAS5th23Component,
    },
    {
        path: 'assignments-6th-semester-January2025',
        component: AssignmentsAS6th23Component,
    },
    // {
    //     path: 'assignments-1st-semester-July2024',
    //     component: AssignmentsAS1st23Component,
    // },
    // {
    //     path: 'assignments-2nd-semester-July2024',
    //     component: AssignmentsAS2nd23Component,
    // },
    // {
    //     path: 'assignments-3rd-semester-July2024',
    //     component: AssignmentsAS3rd23Component,
    // },
    // {
    //     path: 'assignments-4th-semester-July2024',
    //     component: AssignmentsAS4th23Component,
    // },
    // {
    //     path: 'assignments-5th-semester-July2024',
    //     component: AssignmentsAS5th23Component,
    // },
    // {
    //     path: 'assignments-6th-semester-July2024',
    //     component: AssignmentsAS6th23Component,
    // },
    // {
    //     path: 'assignments-1st-semester-January2024',
    //     component: AssignmentsAS1st23Component,
    // },
    // {
    //     path: 'assignments-2nd-semester-January2024',
    //     component: AssignmentsAS2nd23Component,
    // },
    // {
    //     path: 'assignments-3rd-semester-January2024',
    //     component: AssignmentsAS3rd23Component,
    // },
    // {
    //     path: 'assignments-4th-semester-January2024',
    //     component: AssignmentsAS4th23Component,
    // },
    // {
    //     path: 'assignments-5th-semester-January2024',
    //     component: AssignmentsAS5th23Component,
    // },
    // {
    //     path: 'assignments-6th-semester-January2024',
    //     component: AssignmentsAS6th23Component,
    // },
    {
        path: 'for-session-2023-24',
        component: ForSessionC23Component,
    },
    {
        path: 'student-login',
        component: StudentLoginComponent,
    },

    // Here add new pages component

    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
