<div class="mq text-center" behavior="alternate">
    <h5 class="note m text-white">
        <b>Notice:</b> January 2025 Examination Date Sheet.
        <a
            (click)="goToRefresh('/examination-schedule')"
            routerLink="/examination-schedule"
            class="blink"
            style="font-size: large"
            >Check Here</a
        >
    </h5>
</div>
