<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>EXAMINATION SCHEDULE</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image" /></div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="about-text-container">
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Regular & Back Paper Date Sheet January 2025 (Semester
                        Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Theory First Sem.pdf"
                            >REGULAR & BACK PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Theory Second Sem.pdf"
                            >REGULAR & BACK PAPER DATE SHEET:- SECOND
                            SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Theory Third Sem.pdf"
                            >REGULAR & BACK PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Theory Fourth Sem.pdf"
                            >REGULAR & BACK PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Theory Fifth Sem.pdf"
                            >REGULAR & BACK PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Theory Sixth Sem.pdf"
                            >REGULAR & BACK PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet January 2025 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Practical First Sem.pdf"
                            >PRACTICAL DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Practical Second Sem.pdf"
                            >PRACTICAL DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Practical Third Sem.pdf"
                            >PRACTICAL DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Practical Fourth Sem.pdf"
                            >PRACTICAL DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Practical Fifth Sem.pdf"
                            >PRACTICAL DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2025DateSheet/Practical Sixth Sem.pdf"
                            >PRACTICAL DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet November 2024 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/November2024AnnualDateSheet/PRACTICAL DATE SHEET - FIRST YEAR.pdf"
                            >PRACTICAL DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/November2024AnnualDateSheet/PRACTICAL DATE SHEET - SECOND YEAR.pdf"
                            >PRACTICAL DATE SHEET:- SECOND YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/November2024AnnualDateSheet/PRACTICAL DATE SHEET - THIRD YEAR.pdf"
                            >PRACTICAL DATE SHEET:- THIRD YEAR</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet November 2024 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/November2024AnnualDateSheet/BACK PAPER DATE SHEET - FIRST YEAR.pdf"
                            >BACK PAPER DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/November2024AnnualDateSheet/BACK PAPER DATE SHEET - SECOND YEAR.pdf"
                            >BACK PAPER DATE SHEET:- SECOND YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/November2024AnnualDateSheet/BACK PAPER DATE SHEET - THIRD YEAR.pdf"
                            >BACK PAPER DATE SHEET:- THIRD YEAR</a
                        >
                    </p>
                    <!-- <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Regular Paper Date Sheet July 2024 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/1. MAIN EXAMINATION FIRST SEMESTER DATE SHEET OF CALENDAR BATCH-2024.pdf"
                            >REGULAR PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/2. MAIN EXAMINATION SECOND SEMESTER DATE SHEET OF CALENDAR BATCH-2024.pdf"
                            >REGULAR PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/3. MAIN EXAMINATION THIRD SEMESTER DATE SHEET OF  CALENDAR BATCH-2024.pdf"
                            >REGULAR PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/4. MAIN EXAMINATION FOURTH SEMESTER DATE SHEET OF  CALENDAR BATCH-2024.pdf"
                            >REGULAR PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/5. MAIN EXAMINATION FIFTH SEMESTER DATE SHEET OF  CALENDAR BATCH-2024.pdf"
                            >REGULAR PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/6. MAIN EXAMINATION  SIX SEMESTER DATE SHEET OF  CALENDAR BATCH-2024.pdf"
                            >REGULAR PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet July 2024 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/1. BACK PAPER-FIRST SEMEMSTER.pdf"
                            >BACK PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/2. BACK PAPER-SECOND SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/3. BACK PAPER-THIRD SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/4. BACK PAPER-FOURTH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/5. BACK PAPER-FIFTH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/6. BACK PAPER-SIXTH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet July 2024 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/1. PRACTICAL DATE SHEET OF FIRST SEMESTER CALENDAR BATCH -2024.pdf"
                            >PRACTICAL DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/2. PRACTICAL DATE SHEET OF  SECOND  SEMESTER CALENDAR BATCH -2024.pdf"
                            >PRACTICAL DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/3. PRACTICAL DATE SHEET OF  THIRD  SEMESTER CALENDAR BATCH -2024.pdf"
                            >PRACTICAL DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/4. PRACTICAL DATE SHEET OF FOURTH SEMESTER CALENDAR BATCH -2024.pdf"
                            >PRACTICAL DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/5. PRACTICAL DATE SHEET OF FIFTH  SEMESTER CALENDAR BATCH -2024.pdf"
                            >PRACTICAL DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/July2024SemesterDateSheet/6. PRACTICAL DATE SHEET OF SIXTH  SEMESTER CALENDAR BATCH -2024.pdf"
                            >PRACTICAL DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p> 
                <h4
                style="
                    background: #29176d;
                    color: white;
                    padding: 21px;
                    margin-bottom: 15px;
                "
            >
                Practical Date Sheet May 2024 (Annual Mode)
            </h4>
            <p>
                <a
                    target="_blank"
                    href="assets/documents/May2024AnnualDateSheet/PRACTICAL DATE SHEET - FIRST YEAR.pdf"
                    >PRACTICAL DATE SHEET:- FIRST YEAR</a
                >
            </p>
            <p>
                <a
                    target="_blank"
                    href="assets/documents/May2024AnnualDateSheet/PRACTICAL DATE SHEET - SECOND YEAR.pdf"
                    >PRACTICAL DATE SHEET:- SECOND YEAR</a
                >
            </p>
            <p>
                <a
                    target="_blank"
                    href="assets/documents/May2024AnnualDateSheet/PRACTICAL DATE SHEET - THIRD  YEAR.pdf"
                    >PRACTICAL DATE SHEET:- THIRD YEAR</a
                >
            </p>
                    <h4
                    style="
                        background: #29176d;
                        color: white;
                        padding: 21px;
                        margin-bottom: 15px;
                    "
                >
                    Back Paper Date Sheet May 2024 (Annual Mode)
                </h4>
                <p>
                    <a
                        target="_blank"
                        href="assets/documents/May2024AnnualDateSheet/BACK PAPER  DATE SHEET - FIRST YEAR.pdf"
                        >BACK PAPER DATE SHEET:- FIRST YEAR</a
                    >
                </p>
                <p>
                    <a
                        target="_blank"
                        href="assets/documents/May2024AnnualDateSheet/BACK PAPER  DATE SHEET - SECOND YEAR.pdf"
                        >BACK PAPER DATE SHEET:- SECOND YEAR</a
                    >
                </p>
                <p>
                    <a
                        target="_blank"
                        href="assets/documents/May2024AnnualDateSheet/BACK PAPER  DATE SHEET - THIRD YEAR.pdf"
                        >BACK PAPER DATE SHEET:- THIRD YEAR</a
                    >
                </p> -->
                    <!-- <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Regular Paper Date Sheet January 2024 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/1.MAIN EXAMINATION FIRST SEMESTOR DATE SHEET OF ACADEMIC BATCH-2023-24.pdf"
                            >REGULAR PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/2.MAIN EXAMINATION SECOND SEMESTOR DATE SHEET OF ACADEMIC BATCH-2023-24.pdf"
                            >REGULAR PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/3. MAIN EXAMINATION THIRD SEMESTOR DATE SHEET OF ACADEMIC BATCH-2023-24.pdf"
                            >REGULAR PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/4. MAIN EXAMINATION FOURTH SEMESTOR DATE SHEET OF ACADEMIC BATCH-2023-24.pdf"
                            >REGULAR PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/5. MAIN EXAMINATION FIFTH SEMESTOR DATE SHEET OF ACADEMIC BATCH-2023-24.pdf"
                            >REGULAR PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/6. MAIN EXAMINATION  SIX SEMESTOR DATE SHEET OF ACADEMIC BATCH-2023-24.pdf"
                            >REGULAR PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet January 2024 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/Back Paper  1st Semester.pdf"
                            >BACK PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/Back Paper 2nd  Semester.pdf"
                            >BACK PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/Back Paper- 3rd Semester.pdf"
                            >BACK PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/Back Paper 4th Semester.pdf"
                            >BACK PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/Back Paper 5th Semester.pdf"
                            >BACK PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/Back Paper 6th Semester.pdf"
                            >BACK PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet January 2024 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/1. PRACTICAL DATE SHEET OF IST SEMESTER ACADEMIC BATCH -2023-24.pdf"
                            >PRACTICAL DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/2. PRACTICAL DATE SHEET OF  2ND SEMESTER ACADEMIC BATCH -2023-24.pdf"
                            >PRACTICAL DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/3. PRACTICAL DATE SHEET OF  3RD SEMESTER ACADEMIC BATCH -2023-24.pdf"
                            >PRACTICAL DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/4. PRACTICAL DATE SHEET OF  4TH SEMESTER ACADEMIC BATCH -2023-24.pdf"
                            >PRACTICAL DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/5. PRACTICAL DATE SHEET OF 5TH SEMESTER ACADEMIC BATCH -2023-24.pdf"
                            >PRACTICAL DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/January2024DateSheet/6. PRACTICAL DATE SHEET OF  6TH SEMESTER ACADEMIC BATCH -2023-24.pdf"
                            >PRACTICAL DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p> 
                     <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Regular and Back Paper Date Sheet December 2023 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/December23DateSheet/FIRST YEAR (BACK PAPER) MAIN DATE SHEET OF DECEMBER-2023.pdf"
                            >BACK PAPER DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/December23DateSheet/SECOND YEAR (BACK PAPER & REGULAR) MAIN  DATE SHEET OF DECEMBER-2023.pdf"
                            >REGULAR AND BACK PAPER DATE SHEET:- SECOND YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/December23DateSheet/THIRD YEAR (BACK PAPER & REGULAR)  MAIN DATE SHEET OF DECEMBER-2023.pdf"
                            >REGULAR AND BACK PAPER DATE SHEET:- THIRD YEAR</a
                        >
                    </p>
                    <h4
                    style="
                        background: #29176d;
                        color: white;
                        padding: 21px;
                        margin-bottom: 15px;
                    "
                >
                    Practical Date Sheet December 2023 (Annual Mode)
                </h4>
                <p>
                    <a
                        target="_blank"
                        href="assets/documents/December23DateSheet/FIRST YEAR (BACK PAPER) PRACTICAL DATE SHEET OF DECEMBER-2023.pdf"
                        >PRACTICAL DATE SHEET:- FIRST YEAR</a
                    >
                </p>
                <p>
                    <a
                        target="_blank"
                        href="assets/documents/December23DateSheet/SECOND YEAR (BACK PAPER & REGULAR) PRACTICAL DATE SHEET OF DECEMBER-2023.pdf"
                        >PRACTICAL DATE SHEET:- SECOND YEAR</a
                    >
                </p>
                <p>
                    <a
                        target="_blank"
                        href="assets/documents/December23DateSheet/THIRD YEAR (BACK PAPER & REGULAR) PRACTICAL DATE SHEET OF DECEMBER-2023.pdf"
                        >PRACTICAL DATE SHEET:- THIRD YEAR</a
                    >
                </p>
                 <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Regular Paper Date Sheet September 2023 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/1. REGULAR DATE SHEET  FIRST SEMESTER.pdf"
                            >REGULAR PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/2. REGULAR DATE SHEET SECOND  SEMESTER.pdf"
                            >REGULAR PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/3. REGULAR DATE SHEET  THIRD  SEMESTER.pdf"
                            >REGULAR PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/4. REGULAR DATE SHEET FORTH SEMESTER.pdf"
                            >REGULAR PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/5. REGULAR DATE SHEET  FIFTH SEMESTER.pdf"
                            >REGULAR PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/6. REGULAR DATE SHEET SIXTH   SEMESTER.pdf"
                            >REGULAR PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet September 2023 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/1. BACK PAPER - FIRST SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/2. BACK PAPER - SECOND SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/3. BACK PAPER -THIRD SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/4. BACK PAPER -FORTH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/5. BACK PAPER -FIFTH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/6. BACK PAPER -SIXTH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet September 2023 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/1. PRACTICAL PAPER - FIRST SEMESTER.pdf"
                            >PRACTICAL DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/2. PRACTICAL PAPER - SECOND SEMESTER.pdf"
                            >PRACTICAL DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/3. PRACTICAL PAPER - THIRD SEMESTER.pdf"
                            >PRACTICAL DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/4. PRACTICAL PAPER - FORTH SEMESTER.pdf"
                            >PRACTICAL DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/5. PRACTICAL PAPER - FIFTH SEMESTER.pdf"
                            >PRACTICAL DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/September2023DateSheet/6. PRACTICAL PAPER - SIXTH SEMESTER.pdf"
                            >PRACTICAL DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet June 2023 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/June23DateSheet/BACK PAPER DATE SHEET -1ST YEAR.pdf"
                            >BACK PAPER DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/June23DateSheet/BACK PAPER DATE SHEET- 2ND YEAR.pdf"
                            >BACK PAPER DATE SHEET:- SECOND YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/June23DateSheet/BACK PAPER DATE SHEET- 3RD YEAR.pdf"
                            >BACK PAPER DATE SHEET:- THIRD YEAR</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet June 2023 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/June23DateSheet/PRACTICAL DATE SHEET- 1ST YEAR.pdf"
                            class="examsc_a"
                            >PRACTICAL DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/June23DateSheet/PRACTICAL DATE SHEET- 2ND YEAR.pdf"
                            class="examsc_a"
                            >PRACTICAL DATE SHEET:- SECOND YEAR</a
                        >
                    </p>

                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/June23DateSheet/PRACTICAL DATE SHEET- 3RD YEAR.pdf"
                            class="examsc_a"
                            >PRACTICAL DATE SHEET:- THIRD YEAR</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Regular Paper Date Sheet March 2023 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/MAIN EXAMINATION 1ST SEMESTER DATE SHEET OF ACADEMIC BATCH-2022-23.pdf"
                            >REGULAR PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/MAIN EXAMINATION 2ND SEMESTER DATE SHEET OF ACADEMIC BATCH-2022-23.pdf"
                            >REGULAR PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/MAIN EXAMINATION 3RD SEMESTER DATE SHEET OF ACADEMIC BATCH-2022-23.pdf"
                            >REGULAR PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/MAIN EXAMINATION 4TH SEMESTER DATE SHEET OF ACADEMIC BATCH-2022-23.pdf"
                            >REGULAR PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/MAIN EXAMINATION 5TH SEMESTER DATE SHEET OF ACADEMIC BATCH-2022-23.pdf"
                            >REGULAR PAPER DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/MAIN EXAMINATION 6TH SEMESTER DATE SHEET OF ACADEMIC BATCH-2022-23.pdf"
                            >REGULAR PAPER DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet March 2023 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/BACK PAPER DATE SHEET 1ST SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/BACK PAPER DATE SHEET 2ND SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/BACK PAPER DATE SHEET 3RD SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/BACK PAPER DATE SHEET 4TH SEMESTER.pdf"
                            >BACK PAPER DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet March 2023 (Semester Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/PRACTICAL DATE SHEET OF 1ST SEMESTER ACADEMIC BATCH -2022-23.pdf"
                            >PRACTICAL DATE SHEET:- FIRST SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/PRACTICAL DATE SHEET OF 2ND SEMESTER ACADEMIC BATCH -2022-23.pdf"
                            >PRACTICAL DATE SHEET:- SECOND SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/PRACTICAL DATE SHEET OF 3RD  SEMESTER ACADEMIC BATCH -2022-23.pdf"
                            >PRACTICAL DATE SHEET:- THIRD SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/PRACTICAL DATE SHEET OF 4TH  SEMESTER ACADEMIC BATCH -2022-23.pdf"
                            >PRACTICAL DATE SHEET:- FORTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/PRACTICAL DATE SHEET OF 5TH  SEMESTER ACADEMIC BATCH -2022-23.pdf"
                            >PRACTICAL DATE SHEET:- FIFTH SEMESTER</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/MarchExamDateSheet/PRACTICAL DATE SHEET OF 6TH  SEMESTER ACADEMIC BATCH -2022-23.pdf"
                            >PRACTICAL DATE SHEET:- SIXTH SEMESTER</a
                        >
                    </p>

                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Back Paper Date Sheet December 2022 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/date-sheet/December%202022/Back Paper of 1st Year Examination(Yearly Mode).pdf"
                            >BACK PAPER DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/date-sheet/December%202022/Back Paper of 2nd Year Examination(Yearly Mode).pdf"
                            >BACK PAPER DATE SHEET:- SECOND YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/date-sheet/December%202022/Back Paper of 3rd Year Examination(Yearly Mode).pdf"
                            >BACK PAPER DATE SHEET:- THIRD YEAR</a
                        >
                    </p>
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        Practical Date Sheet December 2022 (Annual Mode)
                    </h4>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/date-sheet/December%202022/1st  Year Practical Examination (Yearly Mode).pdf"
                            class="examsc_a"
                            >PRACTICAL DATE SHEET:- FIRST YEAR</a
                        >
                    </p>
                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/date-sheet/December%202022/2nd  Year Practical Examination (Yearly Mode).pdf"
                            class="examsc_a"
                            >PRACTICAL DATE SHEET:- SECOND YEAR</a
                        >
                    </p>

                    <p>
                        <a
                            target="_blank"
                            href="assets/documents/date-sheet/December%202022/3rd  Year Practical Examination (Yearly Mode).pdf"
                            class="examsc_a"
                            >PRACTICAL DATE SHEET:- THIRD YEAR</a
                        >
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>
