<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>ASSIGNMENTS 5th SEMESTER JANUARY 2025</h2>
            <!--</div><div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
        </div>
        <!-- <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>-->
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="about-text-container">
                    <h4
                        style="
                            background: #29176d;
                            color: white;
                            padding: 21px;
                            margin-bottom: 15px;
                        "
                    >
                        ASSIGNMENTS 5th SEMESTER JANUARY 2025
                    </h4>
                    <div class="rightcategory_box">
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-ENGLISH.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(ENGLISH)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-(FASHION DESIGN).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(FASHION DESIGN)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-(FINE ARTS).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(FINE ARTS)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-HINDI.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(HINDI)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-HISTORY.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(HISTORY)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-JMC.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(JMC)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-MATH.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(MATHEMATICS)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-POLITICAL SCIENCE.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for B.A.(POLITICAL
                                        SCIENCE)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-SOCIOLOGY.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A.(SOCIOLOGY)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BA-ECONOMICS.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.A. (ECONOMICS)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/B.COM.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.COM</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/B.COM(HONS).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.COM(Hons)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/B.SC(PCM).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.SC(PCM)</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/B.SC(ZBC).pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for B.SC(ZBC)</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BBA.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                    >
                                        Assignments for BBA</a
                                    >
                                </h6>
                            </div>
                        </div>
                        <div class="blog_box">
                            <div class="blog_heading">
                                <h6
                                    class="download_text"
                                    style="padding: 8px; margin-bottom: 10px"
                                >
                                    <a
                                        class="hover-underline-animation"
                                        href="assets/documents/ASSIGNMENTS  JANUARY 2025/V/BCA.pdf"
                                        style="
                                            font-size: 18px;
                                            font-weight: 400;
                                        "
                                        >Assignments for BCA</a
                                    >
                                </h6>
                            </div>
                        </div>

                        <br class="clear" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
